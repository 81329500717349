<template>
  <v-container fluid id="login-container" class="d-flex align-center justify-center">
    <v-row class="d-flex align-center">
      <v-col offset="1" offset-md="2" cols="10" md="8">
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }" id="validation-observer">
          <v-form role="loginForm" @submit.prevent="handleSubmit(login)" id="login-form">
            <v-row class="text-h4 font-weight-bold mb-8" justify="center">
              {{ $t("signInHeader") }}
            </v-row>
            <v-row class="d-flex flex-column">
              <v-label class="text-left text-subtitle-1 font-weight-bold mb-2">
                {{ $t("emailLabel") }}
              </v-label>
              <v-text-field v-model="loginInfo.email" :rules="emailValidation" id="username-field" filled
                :placeholder="$t('email')" />
            </v-row>
            <v-row class="d-flex flex-column mt-0">
              <v-label class="text-left text-subtitle-1 font-weight-bold mb-2">
                {{ $t("passwordLabel") }}
              </v-label>
              <v-text-field v-model="loginInfo.password" id="password-field" filled :placeholder="$t('password')"
                :type="visiblePassword ? 'text' : 'password'"
                :append-icon="visiblePassword ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
                @click:append="visiblePassword = !visiblePassword" />
            </v-row>
            <div class="row d-flex justify-space-between align-center justify-center">
              <div class="text-subtitle-1 font-weight-medium d-flex justify-center align-center">
                <v-checkbox id="remember-me-checkbox" class="font-weight-medium"></v-checkbox>
                {{ $t("rememberMe") }}
              </div>
              <router-link to="/forgotPassword" class="text-decoration-none font-weight-bold fs-14"
                id="forgot-password-link">
                {{ $t("forgotPassword") }}
              </router-link>
            </div>
            <v-row class="my-8">
              <v-btn type="submit" id="login-button" block color="primary">{{ $t("signIn") }}</v-btn>
            </v-row>
            <v-row class="text-subtitle-1" justify="center">
              {{ $t("doNotHaveAccount") }}
              <router-link to="/signup" class="text-decoration-none font-weight-bold fs-14 ml-6" id="sign-up-link">
                {{ $t("createAccount") }}
              </router-link>
            </v-row>
          </v-form>
        </ValidationObserver>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapMutations, mapActions, mapGetters } = createNamespacedHelpers("user");
import makeAuthService from "@/services/api/auth";
import makeUserService from "@/services/api/user";
import { emailValidationRules } from "@/utils/validation";
import { showAuthErrorToast } from '@/utils/toast';

let authService;
let userService;

export default {
  data() {
    return {
      status: "not_accepted",
      loginForm: true,
      visiblePassword: false,
      loginInfo: {
        email: "",
        password: "",
      },
      emailValidation: emailValidationRules(this),
    };
  },
  computed: {
    ...mapGetters(["currentAccount"])
  },
  created() {
    authService = makeAuthService(this.$api);
    userService = makeUserService(this.$api);
  },
  methods: {
    ...mapActions(["initSession"]),
    login() {
      authService
        .login(this.loginInfo)
        .then(async (response) => {
          const { user } = response.data;
          const orgResponse = await userService.getOrgs(user.uid);
          const { orgs } = orgResponse.data

          let account = {
            handle: user.handle,
            type: "user",
            name: `${user.firstName} ${user.lastName}`,
            roleName: "owner",
            avatarUrl: user.avatarUrl
          };

          if (this.currentAccount) {
            account = orgs.find((a) => a.name === this.currentAccount.name) ?? account;
          }

          this.initSession({ user, currentAccount: account, orgs })

          this.$swal({
            title: this.$t("loginSuccess"),
            icon: "success",
            showConfirmButton: false,
            position: "top-end",
            timer: 2000,
            toast: true,
          });
          const comingFromAuth = ["/login", "/signup", "/"].includes(this.$router.history._startLocation);
          const dest = comingFromAuth
            ? `/${this.currentAccount.handle}/projects`
            : this.$router.history._startLocation;
          this.$router.push(dest);
        })
        .catch((res) => {
          showAuthErrorToast(this.$swal, res.response?.data?.error ?? this.$t('problemProcessingRequest'));
        });
    },
  },
};
</script>
